@charset "utf-8";

$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #dbdbdb;
$beige-lighter: #eff0eb;
$dark: #1e1200;
$dark-light: #343434;
$orange-light: #e3c4a8;
$white: #fdfdff;
$white-dark: #eeeeee;
$red-dark: #750608;
$red: #970c10;
$red-light: #e91919;
$green: #2b5615;
$green-light: #3d7068;
