@import '../styles/colors.scss';

.navbar {
  border-bottom: 2px solid $dark;
  box-shadow: 0 2px 3px rgba($dark, 0.6), 0 0 0 1px rgba($dark, 0.1);
  height: 3.25rem;

  @media screen and (min-width: 1023px) {
    height: 4rem;
  }

  .navbar-item {
    position: relative;
  }

  .logo {
    position: absolute;
    height: 52px;
    max-height: unset !important;
    max-width: unset !important;
    top: 8px;
    width: 52px;

    @media screen and (min-width: 1023px) {
      height: 72px;
      width: 72px;
    }
  }

  .title {
    color: $white;
    margin-left: 60px;
    text-shadow: -1px 1px 0 $dark, 1px 1px 0 $dark, 1px -1px 0 $dark;

    @media screen and (min-width: 1023px) {
      margin-left: 80px;
    }
  }
}
