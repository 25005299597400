@import '../../styles/colors.scss';

.card {
  &.image-upload-cropper {
    border-radius: 10px;
    background-color: $white-dark;

    .cropper {
      border: 6px solid $dark;
    }
  }
}

.image-upload {
  position: relative;

  .image-placeholder {
    width: 100%;
    height: 200px;
    border: 2px dashed #ccc;
    margin: 0 0 1rem 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
  }

  .image-placeholder img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }

  .placeholder-text {
    color: #ccc;
    font-size: 16px;
  }

  .remove-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
