@import './colors.scss';

.form {
  border-bottom: 0.1rem solid $beige-light;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  &.no-border-botton {
    border-bottom: 0;
  }
}
