.card {
  overflow: hidden;
  transition: margin 400ms linear;

  .expand-toggle {
    border-radius: 100%;
    transition: transform 400ms linear;
  }

  &[aria-expanded='false'] {
    margin: 0 0;
  }

  &[aria-expanded='true'] {
    margin: 5px 0;
  }

  .card-content-wrapper {
    transition: height 400ms linear;
  }
}
