.card-title {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 0.25rem;
}

@media only screen and (max-width: 769px) {
  .reverse-row-order {
    flex-direction: row-reverse;
  }
}
