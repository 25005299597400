@charset "utf-8";

html,
body {
  height: 100%;
}

@import './Shared/styles/card.scss';
@import './Shared/styles/form.scss';
@import './Shared/styles/bulmaSettings.scss';

html {
  overflow: auto;
}

.button.facebook {
  background-color: #4267b2;
  color: white;
  border-color: black;

  svg {
    margin-right: 0.5rem;
  }
}
