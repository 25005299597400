.filters-container {
  flex: 1;
  padding: 0 0.75rem;

  .expand-toggle {
    border-radius: 100%;
    transition: transform 400ms linear;
  }

  &[aria-expanded='false'] {
    margin: 0 0;
  }

  &[aria-expanded='true'] {
    margin: 5px 0;
  }

  .filters-wrapper {
    padding: 0;
    transition: height 400ms linear, margin 400ms linear;
  }
}
