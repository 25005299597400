@import '../Shared/styles/colors.scss';

.video-player {
  width: 100%;
  aspect-ratio: 16 / 9;
  height: auto;
}

@media screen and (min-width: 768px) {
  .panel {
    background-color: $dark-light;
    border-radius: 5px;
    box-shadow: 0 5px 5px 0 rgb(0, 0, 0, 0.5);
  }

  .video-player {
    width: 65%;
  }
}
