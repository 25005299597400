@import '../../Shared/styles/colors.scss';

.stats-dropdown {
  .dropdown-content {
    background-color: $white;
  }

  .dropdown-item {
    cursor: pointer;
  }
}
