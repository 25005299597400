.content {
  h1 {
    margin: 1rem 0;
  }

  h2 {
    margin: 0.75rem 0;
  }
  p {
    margin: 0.75rem 0;
  }

  ul {
    list-style: unset;
    margin-left: 1.25rem;
  }
}
