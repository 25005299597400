@import '../../styles/colors.scss';

.consent-link {
  color: $red-dark;
  text-decoration: underline;

  &:hover {
    color: $red-light;
  }
}
